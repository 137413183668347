.App,body {
 background-color: #EFEFEF
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  text-align: center;
  color: white;
}

.App-title {
  font-size: 1.5em;
}
#editaddressFormRadios {
  text-align: left;
  padding-top: 5px
}
#editaddressFormRadios label {
  display: block !important;
}
.App-intro {
  font-size: large;
}
.dimensionRow .text-muted {
  height: 20px;
  display: block;
  margin-bottom: 0;
  padding-bottom: 0;
}
.dimensionRow .col-4 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

form { width:100%; }
form { align-items:flex-start !important }
form .form-group { margin-bottom: 5px !important;  }
form .new-record-wrap { text-align:right; margin-bottom:10px; }
#detail_container { padding-bottom:20px }
.ReactTable { background-color:#fff }
.rt-tr[data-visible="false"] > .rt-expandable {
        visibility: hidden;
}
.react-datepicker-wrapper,.react-datepicker-wrapper div { width: 100%}
.rt-td { vertical-align: bottom;  }
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
.inline-inputs > div {
	display: inline;
}
.maintable {
  font-size: 80%;
}
.maintable .rt-td:nth-child(2) {
  padding: 5px !important;
  margin:0;
}
.maintable .rt-td:nth-child(2) {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin:0;
}
.react-datepicker__time-list {
  padding-left: 0!important
}
.App-header .dropdown {
	line-height: 100%; height: 50px; display: block; background-color: red; position: relative;
}
#output { display: none; }
@media screen and (min-width: 576px) {
#searchFormButtons > span { position: absolute; right: 0; }
#searchFormButtons i { margin-right: 3px }
}
#detail_container .dropzone { position: relative; width:100%; height: 100px; border-width: 2px; border-color: rgb(102, 102, 102); border-style: dashed; border-radius: 5px;  }
#detail_container .dropzone input { display: none}
#detail_container .dropzone p { margin-top : 25px; vertical-align:middle;text-align:center; }
.dropzoneActive { background-color: rgb(23, 162, 184); }
.fileslist .fa-trash { position: absolute; right: 4px; top: 4px; }
.fileslist .fa-trash:hover, .fileUploadList .fa-trash:hover { color: #cc0000; cursor: pointer; }
.zlogo img { max-width:80px; }
.zlogo p { margin-bottom:0; font-size:90%;  }
.mwlogo { max-width: 100%; }
.card { background-color: rgba(0, 0, 0, 0.03)}
h5 { margin-top: 20px}
.modal-header .float-right-text {
  position: absolute;
  right: 50px
}

.radio, .checkbox, .radio-inline, .checkbox-inline {
  position: relative;

}
.radio::fi

.radio label, .checkbox label, .radio-inline label, .checkbox-inline label {
  font-weight: normal;
  cursor: pointer;
  padding-left: 8px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  width: 100%
}
.radio + .radio, .checkbox + .checkbox {
  margin-top: 10px;

}
.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.checkbox i, .checkbox-inline i, .radio i, .radio-inline i {
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  font-size: 28px;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
}
.checkbox span {
  display: block;
  margin-left: 20px
}
.checkbox i.small, .checkbox-inline i.small, .radio i.small, .radio-inline i.small {
  font-size: 18px;
  top:2px;
}
.checkbox input[type=checkbox]:checked + i:before, .checkbox-inline input[type=checkbox]:checked + i:before {
  content:"\f046";
}
.badge {

  padding: 3px 6px;
  vertical-align: top;
  background: #ce9494;
}
