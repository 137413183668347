body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
html,body,#root{
	height: 100%;
}
#loginForm{
	background-color : rgba(255,255,255,0.8);
	border-radius: 5px;
  top : -1000px;
}
#loginForm.show {
  top : 0px;
  transition: top 1s ease;
}
#ztech {
	position:absolute; right:4px; bottom:2px;
	font-size: small;
}
#ztech p {
	text-align: center;
	padding-bottom: 2px;
	margin-bottom: 0;
}
#ztech img {
	height: 50px;
}
.toggle-switch {
  position: relative;
  width: 60px;
}
.toggle-switch input {
  display: none;
}
.toggle-switch label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.toggle-switch-inner {
  width: 200%;
  margin-left: -100%;
  -webkit-transition: margin 0.3s ease-in 0s;
  -moz-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before, .toggle-switch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  text-shadow: 1px 1px 1px #FFFFFF;
  color:#929292;
  background-color: #F5F5F5;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);
}
.toggle-switch-inner:before {
  content: "ON";
  padding-left: 15px;
  -webkit-border-radius: 20px 0 0 20px;
  -moz-border-radius: 20px 0 0 20px;
  border-radius: 20px 0 0 20px;
}
.toggle-switch-inner:after {
  content: "OFF";
  padding-right: 15px;
  text-align: right;
  -webkit-border-radius: 0 20px 20px 0;
  -moz-border-radius: 0 20px 20px 0;
  border-radius: 0 20px 20px 0;
}
.toggle-switch-inner.yesno:before {
  content: "YES";
}
.toggle-switch-inner.yesno:after {
  content: "NO";
}
.toggle-switch-switch {
  width: 20px;
  margin: 0;
  border: 2px solid #d8d8d8;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  color: #f8f8f8;
  line-height: 1em;
  text-shadow: 0 0px 1px #ADADAD;
  text-align: center;
  -webkit-transition: all 0.3s ease-in 0s;
  -moz-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
  background-color: #f0f0f0;
  background-image: -webkit-linear-gradient(top, #f0f0f0, #dfdfdf);
  background-image:    -moz-linear-gradient(top, #f0f0f0, #dfdfdf);
  background-image:     -ms-linear-gradient(top, #f0f0f0, #dfdfdf);
  background-image:      -o-linear-gradient(top, #f0f0f0, #dfdfdf);
  background-image:         linear-gradient(to bottom, #f0f0f0, #dfdfdf);
}
.toggle-switch input:checked + .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch input:checked + .toggle-switch-inner + .toggle-switch-switch {
  right: 0px;
}
.toggle-switch-danger input:checked + .toggle-switch-inner + .toggle-switch-switch {
  border:2px solid #D15E5E;
  background: #D15E5E;
}
.toggle-switch-warning input:checked + .toggle-switch-inner + .toggle-switch-switch {
  border:2px solid #DFD271;
  background: #DFD271;
}
.toggle-switch-info input:checked + .toggle-switch-inner + .toggle-switch-switch {
  border:2px solid #7BC5D3;
  background: #7BC5D3;
}
.toggle-switch-success input:checked + .toggle-switch-inner + .toggle-switch-switch {
  border:2px solid #63CC9E;
  background: #63CC9E;
}
.toggle-switch-primary input:checked + .toggle-switch-inner + .toggle-switch-switch {
  border:2px solid #6AA6D6;
  background: #6AA6D6;
}
.animated {
  padding: 50px;
}
